import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { RootState } from '../../redux/store';
import { updateSubmission } from '../../redux/slices/submissions';

interface Teacher {
  id: number;
  first_name: string;
  last_name: string;
  role: string;
}

interface Submission {
  id: number;
  test: {
    test_title: string;
    subject: {
      id: number;
    };
  };
  user: {
    name: string;
  };
  assigned_teacher: Teacher | null;
  available_teachers: Teacher[];
  check_status: boolean;
}

interface SubmissionRowProps {
  submission: Submission;
  isEven: boolean;
  setTeacherChange: React.Dispatch<React.SetStateAction<boolean>>;
  teacherChange: boolean;
}

const SubmissionRow: React.FC<SubmissionRowProps> = ({
  submission: initialSubmission,
  isEven,
  teacherChange,
  setTeacherChange,
}) => {
  const token = useAppSelector((state: RootState) => state.auth.access);
  const current = useAppSelector(
    (state: RootState) => state.submission.current
  );
  const role = useAppSelector((state: RootState) => state.auth.role);
  const dispatch = useAppDispatch();
  const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
  const navHandler = useNavigate();
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [submission, setSubmission] = useState(initialSubmission);

  const selectTeacher = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTeacherId =
      e.target.value === 'null' ? null : parseInt(e.target.value);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    BaseUrl.put(
      `admin/submission/${submission?.id}/`,
      { assigned_teacher_id: selectedTeacherId },
      config
    )
      .then((res) => {
        const updatedTeacher =
          teachers.find((teacher) => teacher.id === selectedTeacherId) || null;

        const updatedSubmission = {
          ...submission,
          assigned_teacher: updatedTeacher,
        };

        setSubmission(updatedSubmission);
        dispatch(updateSubmission(updatedSubmission));

        setTeacherChange(true);

        console.log('Teacher assignment successful:', res.data);
      })
      .catch((err) => {
        if (err.response) {
          console.log('Error status:', err.response.status);
          console.log('Error data:', err.response.data);
        } else {
          console.log('Unknown error:', err);
        }
      });
  };

  const fetchTeachers = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await BaseUrl.get('admin/teacher', config);
      setTeachers(response.data.results);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  return (
    <div
      className={`grid grid-cols-[1fr_1fr_1fr_1fr] gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}
    >
      <div
        onClick={() => navHandler(`/submission/${submission?.id}`)}
        className="overflow-hidden  justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5"
      >
        <span className="truncate">{submission.test.test_title || '-'}</span>
      </div>
      <div
        onClick={() => navHandler(`/submission/${submission?.id}`)}
        className="overflow-hidden justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5"
      >
        <span className="truncate">{submission.user.name || '-'}</span>
      </div>

      {role === 'admin' && submission && teachers && (
        <div className="overflow-hidden justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
          <select
            onChange={(e) => selectTeacher(e)}
            value={
              submission.assigned_teacher
                ? submission.assigned_teacher.id
                : 'null'
            }
            className="col-span-2 w-full outline-none border border-1 border-zinc-300 p-2 rounded-lg"
          >
            <option value="null"> ---- </option>
            {teachers.length > 0 &&
              teachers.map((teacher) =>
                teacher.role === 'teacher' ? (
                  <option key={teacher.id} value={teacher.id}>
                    {teacher.first_name} {teacher.last_name}
                  </option>
                ) : null
              )}
          </select>
        </div>
      )}

      <div
        onClick={() => navHandler(`/submission/${submission?.id}`)}
        className="overflow-hidden justify-center items-start p-4 text-base leading-6 border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5"
      >
        {submission.check_status ? (
          <img src="/tick.svg" alt="true" />
        ) : (
          <img src="/redCross.svg" alt="false" />
        )}
      </div>
    </div>
  );
};

export default SubmissionRow;
