import React, { useState } from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import { RootState } from '../../redux/store';
import BaseUrl from '../../BaseUrl';
import { setAccess } from '../../redux/slices/auth';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

interface Coupon {
  id: string;
  title: string;
}

interface SubjectRowProps {
  subject: Coupon;
  isEven: boolean;
}

const SubjectRow: React.FC<SubjectRowProps> = ({ subject, isEven }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(subject.title);
  const [originalTitle, setOriginalTitle] = useState(subject.title);
  const token = useAppSelector((state: RootState) => state.auth.access);
  const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
  const dispatch = useDispatch();

  const toSentenceCase = (str: string) => {
    if (!str) return '';
    return str.toUpperCase();
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    const formattedTitle = editedTitle || subject.title;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    BaseUrl.put(
      `admin/subject/${subject.id}/`,
      {
        title: formattedTitle,
      },
      config
    )
      .then((res) => {
        const curr = subject;
        const modifiedData = {
          ...res.data,
          current: curr,
        };
        setOriginalTitle(formattedTitle);
        // toast.success('Update Succesfully');
      })
      .catch((err) => {
        if (err.response) {
          console.log('Error status:', err.response.status);
          console.log('Error data:', err.response.data);
          if (err.response.status === 403) {
            dispatch(setAccess(''));
          }
        } else {
          console.log('Unknown error:', err);
        }
      });
  };

  const handleReset = () => {
    setEditedTitle(originalTitle);
    setIsEditing(false);
  };

  return (
    <div
      className={`grid grid-cols-4 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}
    >
      <div className="col-span-2 justify-center items-start p-4 text-base whitespace-nowrap border-t border-solid border-zinc-200 max-md:pr-5 overflow-hidden text-ellipsis">
        {isEditing ? (
          <input
            type="text"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1 w-full uppercase"
            // style={{ textTransform: 'uppercase' }}
          />
        ) : (
          <span className="overflow-hidden text-ellipsis whitespace-nowrap">
            {toSentenceCase(originalTitle) || subject.title}
          </span>
        )}
      </div>
      <div className="col-span-2 flex justify-end items-center space-x-4 p-4">
        {isEditing ? (
          <>
            <button
              onClick={handleSave}
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              Save
            </button>
            <button
              onClick={handleReset}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Reset
            </button>
          </>
        ) : (
          <button
            onClick={handleEdit}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Edit
          </button>
        )}
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default SubjectRow;
