import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import SubmissionsTable from './SubmissionsTable';
import {
  setCurrentSubmissionPage,
  setSubmissionResults,
  setSubmissions,
} from '../../redux/slices/submissions';
import Loader from '../Loader';

function Submissions() {
  interface TestTypes {
    id: string;
    title: string;
  }

  interface SubjectTypes {
    id: string;
    title: string;
    course_type: number;
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.submission.previous);
  const next = useAppSelector((state: RootState) => state.submission.next);
  const current = useAppSelector(
    (state: RootState) => state.submission.current
  );
  const role = useAppSelector((state: RootState) => state.auth.role);
  const teacherId = useAppSelector((state: RootState) => state.auth.id);
  const dispatch = useAppDispatch();

  const [testSeries, setTestSeries] = useState('');
  const [testSeriesList, setTestSeriesList] = useState<TestTypes[]>([]);
  // const [number, setNumber] = useState('');
  // const [debouncedNumber, setDebouncedNumber] = useState(number);
  const [testSeriesType, setTestSeriesType] = useState('');
  const [testSeriesTypes, setTestSeriesTypes] = useState<TestTypes[]>([]);
  const [courseType, setCourseType] = useState('');
  const [courseTypes, setCourseTypes] = useState<TestTypes[]>([]);
  const [subject, setSubject] = useState('');
  const [subjects, setSubjects] = useState<SubjectTypes[]>([]);
  const [filteredSubjects, setFilteredSubjects] = useState<SubjectTypes[]>([]);
  const [checkStatus, setCheckStatus] = useState('');
  const [assigned, setAssigned] = useState(false);
  const [teacherChange, setTeacherChange] = useState(false);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true); 
  const [debounceTimeout, setDebounceTimeout] = useState<number | null>(null); 
  const [isFiltering, setIsFiltering] = useState(false); 

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      Promise.all([
        BaseUrl.get(`admin/non-paginated-test-series`, config),
        BaseUrl.get(`admin/non-paginated-test-series-type`, config),
        BaseUrl.get(`admin/non-paginated-course-type/`, config),
        BaseUrl.get(`admin/non-paginated-subject`, config),
      ])
        .then(
          ([
            testSeriesRes,
            testSeriesTypesRes,
            courseTypesRes,
            subjectsRes,
          ]) => {
            setTestSeriesList(testSeriesRes.data);
            setTestSeriesTypes(testSeriesTypesRes.data);
            setCourseTypes(courseTypesRes.data);
            setSubjects(subjectsRes.data);
            setLoading(false);
          }
        )
        .catch((err) => {
          if (err.response?.status === 403) dispatch(setAccess(''));
          console.error(err);
          setLoading(false);
        });
    }
  }, [token, navHandler, dispatch]);

  useEffect(() => {
    if (courseType) {
      const filtered = subjects.filter(
        (subject) => subject.course_type === parseInt(courseType)
      );
      setFilteredSubjects(filtered);
    } else {
      setFilteredSubjects(subjects);
    }
  }, [courseType, subjects]);

  const fetchSubmissions = () => {
    if (!token) return;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const baseUrl =
      role === 'admin' ? 'filter-submission/' : 'teacher/submissions/';
    const teacherParam = role === 'admin' ? '' : `teacher_id=${teacherId}&`;
    const url = `${baseUrl}?${teacherParam}page=${current}&test_series_type=${testSeriesType}&course_type=${courseType}&user_name=${name}&test_series=${testSeries}${
      checkStatus ? `&check_status=${checkStatus}` : ''
    }&subject=${subject}`;

    setLoading(true);

    BaseUrl.get(url, config)
      .then((res) => {
        const modifiedData = {
          ...res.data,
          current: res.data.current,
        };
        dispatch(setSubmissions(modifiedData));
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 403) dispatch(setAccess(''));
        console.error(err);

        dispatch(
          setSubmissions({
            count: 0,
            previous: null,
            next: null,
            current: 0,
            results: [],
          })
        );
        setLoading(false);
      });
  };

  const handleInputChange = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setDebounceTimeout(
      window.setTimeout(() => {
        setIsFiltering(true); 
      }, 300) 
    );
  };

  useEffect(() => {
    if (isFiltering) {
      fetchSubmissions();
    }
  }, [isFiltering]);

  useEffect(() => {
    if (isFiltering) {
      setIsFiltering(false);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      fetchSubmissions();
    }
  }, [
    current,
    courseType,
    testSeriesType,
    testSeries,
    checkStatus,
    subject,
    teacherId,
  ]);

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Submissions" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col sm:flex-row gap-5 mx-auto sm:mx-0 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Submissions
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Reports</p>
              <span>›</span>
              <span>Submissions</span>
            </nav>
          </div>
        </header>
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:max-w-full">
          <>
            <div className="my-6">
              <div className="flex justify-between items-center mb-4">
                <p className="text-lg font-semibold text-gray-700">Filters</p>
                <button
                  onClick={() => {
                    setTestSeriesType('');
                    setCourseType('');
                    setName('');
                    setTestSeries('');
                    setSubject('');
                    setCheckStatus('');
                  }}
                  className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                >
                  Clear All Filters
                </button>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    User:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Username"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      handleInputChange();
                    }}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label
                    htmlFor="testSeriesType"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Test Series Type:
                  </label>
                  <select
                    id="testSeriesType"
                    name="testSeriesType"
                    value={testSeriesType}
                    onChange={(e) => setTestSeriesType(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">----</option>
                    {testSeriesTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="testSeries"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Test Series:
                  </label>
                  <select
                    id="testSeries"
                    name="testSeries"
                    value={testSeries}
                    onChange={(e) => setTestSeries(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">----</option>
                    {testSeriesList.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="courseType"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Course Type:
                  </label>
                  <select
                    id="courseType"
                    name="courseType"
                    value={courseType}
                    onChange={(e) => setCourseType(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">----</option>
                    {courseTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="subjects"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Subject:
                  </label>
                  <select
                    id="subjects"
                    name="subjects"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">----</option>
                    {filteredSubjects.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="checkStatus"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Check Status:
                  </label>
                  <select
                    id="checkStatus"
                    name="checkStatus"
                    value={checkStatus}
                    onChange={(e) => setCheckStatus(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">----</option>
                    <option value="True">Checked</option>
                    <option value="False">Unchecked</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 text-xs my-4 gap-4 font-extrabold">
              {role === 'admin' && (
                <>
                  <p
                    onClick={() => setAssigned(false)}
                    className={`p-2 rounded-full border text-center cursor-pointer ${
                      assigned
                        ? 'border-black text-black'
                        : 'border-blue-600 bg-blue-200 text-blue-700'
                    }`}
                  >
                    Teacher Not Assigned
                  </p>
                  <p
                    onClick={() => setAssigned(true)}
                    className={`p-2 rounded-full border text-center cursor-pointer ${
                      assigned
                        ? 'border-blue-600 bg-blue-200 text-blue-700'
                        : 'border-black text-black'
                    }`}
                  >
                    Teacher Assigned
                  </p>
                </>
              )}
            </div>
            <div className="relative">
              {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                  <Loader />
                </div>
              )}
              <SubmissionsTable
                teacherChange={teacherChange}
                setTeacherChange={setTeacherChange}
                assigned={role == 'admin' ? assigned : true}
              />
            </div>
          </>
        </main>
        <div className="flex gap-2 w-full justify-center items-center m-4">
          {prev && (
            <button
              onClick={() => dispatch(setCurrentSubmissionPage(current - 1))}
              className="px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer"
            >
              {'<'}
            </button>
          )}
          <div className="px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer">
            {current}
          </div>
          {next && (
            <button
              onClick={() => dispatch(setCurrentSubmissionPage(current + 1))}
              className="px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer"
            >
              {'>'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Submissions;
